import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import accountUsageMiddleware from './middleware/AccountUsageMiddleware';
import balancesMiddleware from './middleware/BalancesMiddleware';
import chartDataMiddleware from './middleware/ChartDataMiddleware';
import openPositionMiddleware from './middleware/OpenPositionMiddleware';
import orderStatusMiddleware from './middleware/OrderStatusMiddleware';
import serverEventsMiddleware from './middleware/ServerEventsMiddleware';

import { PersistConfig, createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { migrations } from './migrations/settings';
import rootReducer from './reducers';

const MIGRATION_DEBUG = true;

// Add reducers to persist their state: 'market', 'pair', etc
// Read on how this works https://github.com/rt2zz/redux-persist#blacklist--whitelist
const persistConfig: PersistConfig<any> = {
    key: 'root',
    whitelist: [
        'settings',
        'workspace',
        'marketPair',
        'liteMarket',
        'traderMarket',
        'xplorSpotLayout',
        'xplorTraderLayout'
    ],
    storage,
    version: 16,
    migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG })
};

const persistedReducer: typeof rootReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(
            chartDataMiddleware,
            orderStatusMiddleware,
            balancesMiddleware,
            accountUsageMiddleware,
            openPositionMiddleware,
            serverEventsMiddleware
        )
});

export const persistor = persistStore(store, null, () => {
    console.log('Rehydration finished');
});

export const purgeStore = () => persistor.purge();
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
